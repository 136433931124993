import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Spinner, Alert } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';

const BlogPostPage = ({ siteId, apiKey, apiUrl = 'https://blog.yourdomain.com/api' }) => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${apiUrl}/blogs/slug/${slug}`, {
          headers: {
            'X-API-Key': apiKey,
          },
          params: {
            siteId: siteId
          }
        });
        setBlog(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load blog post');
        setLoading(false);
      }
    };
    
    fetchBlog();
  }, [slug, siteId, apiKey, apiUrl]);
  
  if (loading) return <Container className="my-5"><Spinner animation="border" /></Container>;
  if (error) return <Container className="my-5"><Alert variant="danger">{error}</Alert></Container>;
  if (!blog) return <Container className="my-5"><Alert variant="info">Blog post not found</Alert></Container>;
  
  return (
    <Container className="my-5">
      <Link to="/blog" className="btn btn-outline-secondary mb-4">
        &larr; Back to Blogs
      </Link>
      
      {blog.featuredImage && (
        <img 
          src={blog.featuredImage} 
          alt={blog.title} 
          className="img-fluid rounded mb-4" 
          style={{ maxHeight: '400px', width: '100%', objectFit: 'cover' }} 
        />
      )}
      
      <h1 className="mb-3">{blog.title}</h1>
      
      <div className="text-muted mb-4">
        {blog.author && <span>By {blog.author.name} | </span>}
        <span>{new Date(blog.createdAt).toLocaleDateString()}</span>
        
        {blog.category && (
          <span className="ms-2 badge bg-secondary">{blog.category}</span>
        )}
      </div>
      
      <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.content }} />
      
      {blog.tags && blog.tags.length > 0 && (
        <div className="mt-4">
          {blog.tags.map(tag => (
            <span key={tag} className="badge bg-light text-dark me-2">
              {tag}
            </span>
          ))}
        </div>
      )}
    </Container>
  );
};

export default BlogPostPage;