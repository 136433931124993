import React from 'react';
import styles from './PrivacyPolicy.module.css';
import { MdPhone, MdEmail, MdLocationOn } from 'react-icons/md';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <div className={styles.privacyWrapper}>
        <div className={styles.header}>
          <h1 className={styles.title}>Privacy Policy</h1>
          <p className={styles.effectiveDate}>Effective Date: March 24, 2025</p>
        </div>

        <div className={styles.content}>
          <div className={styles.card}>
            <p className={styles.intro}>
              At KnightsFinEstates.com, we are dedicated to safeguarding your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you engage with our website and services. By accessing our site, you agree to the practices detailed herein.
            </p>
          </div>

          <div className={styles.card}>
            <h2 className={styles.sectionTitle}>1. Information We Collect</h2>
            <p>We gather the following types of data:</p>
            <ul className={styles.list}>
              <li><span>Personal Information:</span> Name, phone number, email address, residential address, property preferences, and details submitted via forms or correspondence.</li>
              <li><span>Usage Data:</span> Details of your website interactions, including IP address, browser type, pages visited, and session duration.</li>
              <li><span>Cookies:</span> Used to enhance functionality and user experience, manageable through your browser settings.</li>
            </ul>
          </div>

          <div className={styles.card}>
            <h2 className={styles.sectionTitle}>2. How We Use Your Information</h2>
            <ul className={styles.list}>
              <li>Responding to property inquiries</li>
              <li>Providing tailored real estate suggestions</li>
              <li>Contacting you for follow-ups or updates</li>
              <li>Enhancing site performance and user experience</li>
              <li>Sending marketing communications (with consent)</li>
              <li>Meeting legal requirements</li>
            </ul>
          </div>

          <div className={styles.card}>
            <h2 className={styles.sectionTitle}>3. Sharing of Information</h2>
            <p>Your personal data is never sold or rented. It may be shared with:</p>
            <ul className={styles.list}>
              <li>Trusted partners aiding service delivery (e.g., legal or loan services)</li>
              <li>Regulatory authorities as required by law</li>
              <li>Internal staff for operational purposes</li>
            </ul>
          </div>

          <div className={styles.card}>
            <h2 className={styles.sectionTitle}>4. Data Security</h2>
            <p>We employ robust measures to protect your data. However, no online transmission is entirely secure, and while we use industry-standard protections, absolute security cannot be guaranteed.</p>
          </div>

          <div className={styles.card}>
            <h2 className={styles.sectionTitle}>5. Your Rights</h2>
            <p>You may:</p>
            <ul className={styles.list}>
              <li>Access, update, or delete your data</li>
              <li>Opt out of marketing communications</li>
              <li>Inquire about data usage</li>
              <li>Lodge a complaint with a data protection authority</li>
            </ul>
            <p>Contact us at <a href="mailto:team@knightsfinestates.com" className={styles.link}>team@knightsfinestates.com</a> to exercise these rights.</p>
          </div>

          <div className={styles.card}>
            <h2 className={styles.sectionTitle}>6. External Links</h2>
            <p>Our site may link to external websites, for which we are not responsible regarding privacy practices or content.</p>
          </div>

          <div className={styles.card}>
            <h2 className={styles.sectionTitle}>7. Changes to This Policy</h2>
            <p>Updates to this policy will be reflected here with a revised "Effective Date." Continued use of our site post-update implies acceptance.</p>
          </div>

          <div className={styles.card}>
            <h2 className={styles.sectionTitle}>8. Contact Us</h2>
            <p>For inquiries about this policy, reach us at:</p>
            <div className={styles.contactGrid}>
              <div className={styles.contactCard}>
                <div className={styles.iconWrapper}>
                  <MdPhone size={28} />
                </div>
                <div className={styles.contactText}>
                  <p>Request a Call Back</p>
                  <h3>+917756083523</h3>
                </div>
              </div>
              <div className={styles.contactCard}>
                <div className={styles.iconWrapper}>
                  <MdEmail size={28} />
                </div>
                <div className={styles.contactText}>
                  <p>Email Us</p>
                  <a href="mailto:team@knightsfinestates.com" className={styles.emailLink}>
                    team@knightsfinestates.com
                  </a>
                </div>
              </div>
              {/* <div className={styles.contactCard}>
                <div className={styles.iconWrapper}>
                  <MdLocationOn size={28} />
                </div>
                <div className={styles.contactText}>
                  <p>506, 5th Floor, Pyramid Axis, Behind Croma,</p>
                  <p>Baner, Pune, Maharashtra, India, 411045</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </>
  );
};

export default PrivacyPolicy;