import React from 'react';
import BlogComponent from '../minicomponents/BlogComponent';

const BlogPage = () => {
  return (
    <div className="page-content">
      <h1>Our Blog</h1>
      
      <BlogComponent 
        siteId="your-site-id" 
        apiKey="your-api-key" 
        apiUrl="https://blog.yourdomain.com/api"
        theme="light"
        showFeatured={true}
      />
    </div>
  );
};

export default BlogPage;