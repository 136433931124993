import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Pagination, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { FaSearch, FaThLarge, FaList, FaCalendarAlt, FaEye, FaUser } from 'react-icons/fa';
import './BlogComponent.css';

const BlogComponent = ({ 
  siteId, 
  apiKey, 
  apiUrl = 'https://blog.yourdomain.com/api', 
  theme = 'light',
  showFeatured = true,
  maxItems = 6,
  defaultView = 'grid'
}) => {
  const [blogs, setBlogs] = useState([]);
  const [featuredBlog, setFeaturedBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [viewMode, setViewMode] = useState(defaultView);
  
  // Fetch blogs for the specific site
  const fetchBlogs = async (page = 1, keyword = '', category = '') => {
    setLoading(true);
    try {
      let url = `${apiUrl}/blogs/external/site/${siteId}?pageNumber=${page}&published=true`;
      
      if (keyword) url += `&keyword=${encodeURIComponent(keyword)}`;
      if (category) url += `&category=${encodeURIComponent(category)}`;
      
      const response = await axios.get(url, {
        headers: {
          'X-API-Key': apiKey,
        }
      });
      
      const { blogs, pages } = response.data;
      
      // Set featured blog to the most viewed blog
      if (showFeatured && blogs.length > 0 && page === 1) {
        const sortedByViews = [...blogs].sort((a, b) => b.viewCount - a.viewCount);
        setFeaturedBlog(sortedByViews[0]);
        // Remove featured from regular listing to avoid duplication
        setBlogs(blogs.filter(blog => blog._id !== sortedByViews[0]._id));
      } else {
        setBlogs(blogs);
      }
      
      setTotalPages(pages);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching blogs:', err);
      setError('Failed to load blogs. Please try again later.');
      setLoading(false);
    }
  };
  
  // Fetch categories for filtering
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/blogs/categories`, {
        headers: {
          'X-API-Key': apiKey,
        }
      });
      setCategories(response.data);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };
  
  // Initial data fetch
  useEffect(() => {
    fetchBlogs(currentPage, searchTerm, selectedCategory);
    fetchCategories();
  }, [siteId, apiKey]);
  
  // Handle search
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchBlogs(1, searchTerm, selectedCategory);
  };
  
  // Handle category filter
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
    fetchBlogs(1, searchTerm, category);
  };
  
  // Handle pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchBlogs(pageNumber, searchTerm, selectedCategory);
    
    // Scroll to top of blog component
    document.querySelector('.blog-showcase').scrollIntoView({ behavior: 'smooth' });
  };
  
  // Format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  // Render pagination
  const renderPagination = () => {
    const items = [];
    
    // Add first page
    items.push(
      <Pagination.Item 
        key={1} 
        active={currentPage === 1}
        onClick={() => handlePageChange(1)}
      >
        1
      </Pagination.Item>
    );
    
    // Add ellipsis if needed
    if (currentPage > 3) {
      items.push(<Pagination.Ellipsis key="ellipsis-1" disabled />);
    }
    
    // Add pages before and after current
    for (let page = Math.max(2, currentPage - 1); page <= Math.min(totalPages - 1, currentPage + 1); page++) {
      items.push(
        <Pagination.Item 
          key={page} 
          active={currentPage === page}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }
    
    // Add ellipsis if needed
    if (currentPage < totalPages - 2) {
      items.push(<Pagination.Ellipsis key="ellipsis-2" disabled />);
    }
    
    // Add last page if there is more than one page
    if (totalPages > 1) {
      items.push(
        <Pagination.Item 
          key={totalPages} 
          active={currentPage === totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
    
    return <Pagination className="blog-pagination">{items}</Pagination>;
  };
  
  // Render featured blog
  const renderFeaturedBlog = () => {
    if (!featuredBlog) return null;
    
    return (
      <div className="blog-featured">
        <div className="blog-featured-image">
          <img src={featuredBlog.featuredImage} alt={featuredBlog.title} />
        </div>
        <div className="blog-featured-content">
          <div className="blog-featured-title">
            <a href={`/blog/${featuredBlog.slug}`}>{featuredBlog.title}</a>
          </div>
          <div className="blog-featured-meta">
            <span className="category">{featuredBlog.category}</span>
            <span>{formatDate(featuredBlog.createdAt)}</span>
            <span><FaEye className="me-1" />{featuredBlog.viewCount} views</span>
          </div>
        </div>
      </div>
    );
  };
  
  // Render loading skeletons
  const renderSkeletons = () => {
    return Array(maxItems).fill().map((_, idx) => (
      <Col key={`skeleton-${idx}`} className={viewMode === 'grid' ? undefined : '12'}>
        <Card className="blog-card">
          <div className="blog-skeleton-image skeleton"></div>
          <Card.Body>
            <div className="blog-skeleton-title skeleton"></div>
            <div className="blog-skeleton-text skeleton"></div>
            <div className="blog-skeleton-text skeleton"></div>
            <div className="blog-skeleton-text skeleton"></div>
          </Card.Body>
        </Card>
      </Col>
    ));
  };
  
  // Render blog cards
  const renderBlogs = () => {
    if (loading) return renderSkeletons();
    
    if (blogs.length === 0) {
      return (
        <Col xs={12}>
          <Alert variant="info">
            No blogs found. Please try a different search or category.
          </Alert>
        </Col>
      );
    }
    
    return blogs.map(blog => (
      <Col key={blog._id} className={viewMode === 'grid' ? undefined : '12'}>
        <Card className="blog-card">
          <div className="blog-card-image">
            <img src={blog.featuredImage} alt={blog.title} />
          </div>
          <Card.Body className="blog-card-content">
            <div className="blog-card-meta">
              <span className="category">{blog.category}</span>
              <span>{formatDate(blog.createdAt)}</span>
            </div>
            <h3 className="blog-card-title">
              <a href={`/blog/${blog.slug}`}>{blog.title}</a>
            </h3>
            {blog.tags && blog.tags.length > 0 && (
              <div className="blog-tags">
                {blog.tags.slice(0, 3).map((tag, idx) => (
                  <span key={idx} className="blog-tag">{tag}</span>
                ))}
                {blog.tags.length > 3 && <span className="blog-tag">+{blog.tags.length - 3}</span>}
              </div>
            )}
            <div className="blog-card-excerpt">{blog.excerpt}</div>
            <div className="blog-card-footer">
              <div className="blog-author">
                <div className="blog-author-name">
                  <FaUser className="me-1" />
                  {blog.author.name}
                </div>
              </div>
              <div className="blog-read-more">
                <a href={`/blog/${blog.slug}`}>Read More</a>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    ));
  };
  
  return (
    <div className={`blog-showcase ${theme === 'dark' ? 'dark-theme' : ''}`}>
      <Container className="blog-container">
        <div className="blog-header">
          <h2>Latest Articles</h2>
          <div className="d-flex align-items-center gap-2">
            <div className="blog-search">
              <Form onSubmit={handleSearch}>
                <Form.Control
                  type="text"
                  placeholder="Search blogs..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FaSearch />
              </Form>
            </div>
            <div className="blog-view-toggle">
              <Button 
                variant="link" 
                className={viewMode === 'grid' ? 'active' : ''}
                onClick={() => setViewMode('grid')}
              >
                <FaThLarge />
              </Button>
              <Button 
                variant="link" 
                className={viewMode === 'list' ? 'active' : ''}
                onClick={() => setViewMode('list')}
              >
                <FaList />
              </Button>
            </div>
          </div>
        </div>
        
        {categories.length > 0 && (
          <div className="blog-filters">
            <div 
              className={`category-filter ${selectedCategory === '' ? 'active' : ''}`}
              onClick={() => handleCategoryChange('')}
            >
              All
            </div>
            {categories.map((category, idx) => (
              <div 
                key={idx} 
                className={`category-filter ${selectedCategory === category ? 'active' : ''}`}
                onClick={() => handleCategoryChange(category)}
              >
                {category}
              </div>
            ))}
          </div>
        )}
        
        {error && <Alert variant="danger">{error}</Alert>}
        
        {showFeatured && renderFeaturedBlog()}
        
        <Row className={viewMode === 'grid' ? 'blog-grid' : 'blog-list'}>
          {renderBlogs()}
        </Row>
        
        {totalPages > 1 && renderPagination()}
      </Container>
    </div>
  );
};

export default BlogComponent;