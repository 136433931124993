// src/apiConfig.js

// Change this URL manually based on your environment
// const BASE_URL = 'http://localhost:5000';  // For development
const BASE_URL = '';                    // For production (uncomment this and comment above line when in production)

// Single function to get any API endpoint
export const getApiUrl = (endpoint, params = '') => {
  return `${BASE_URL}/${endpoint}${params ? `?${params}` : ''}`;
};

// https://knightsfinestates-backend-1.onrender.com


// for .env

// const BASE_URL = process.env.REACT_APP_BASE_URL || '';  // Use an empty string for production

// export const getApiUrl = (endpoint, params = '') => {
//   return `${BASE_URL}/${endpoint}${params ? `?${params}` : ''}`;
// };