import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Expand, X, ZoomIn, ZoomOut } from 'lucide-react';
import styles from './FloorplanGallery.module.css';
import { getApiUrl } from './apiConfig';

const FloorplanGallery = () => {
  const { id } = useParams();
  const [floorPlans, setFloorPlans] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const dragRef = useRef({ startX: 0, startY: 0, initialX: 0, initialY: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await fetch(getApiUrl(`api/sale/${id}`));
        const data = await response.json();
        setFloorPlans(data.floorPlan || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching floor plans:', error);
        setLoading(false);
      }
    };
    fetchProperty();
  }, [id]);

  const handleNavigation = (direction) => {
    setCurrentIndex((prev) =>
      direction === 'prev'
        ? prev === 0
          ? floorPlans.length - 1
          : prev - 1
        : prev === floorPlans.length - 1
        ? 0
        : prev + 1
    );
    resetZoomAndPosition();
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    resetZoomAndPosition();
  };

  const handleZoom = (delta) => {
    setZoomLevel((prev) => {
      const newZoom = delta > 0 
        ? Math.min(prev + 0.2, 3)  // Max zoom 3x
        : Math.max(prev - 0.2, 0.5); // Min zoom 0.5x
      return newZoom;
    });
  };

  const handleWheel = (e) => {
    e.preventDefault();
    handleZoom(-e.deltaY);
  };

  const resetZoomAndPosition = () => {
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
  };

  // Improved dragging logic
  const startDrag = (clientX, clientY) => {
    if (zoomLevel <= 1) return;
    setIsDragging(true);
    dragRef.current = {
      startX: clientX,
      startY: clientY,
      initialX: position.x,
      initialY: position.y,
    };
  };

  const moveDrag = (clientX, clientY) => {
    if (!isDragging || zoomLevel <= 1) return;

    const dx = (clientX - dragRef.current.startX) / zoomLevel; // Adjust for zoom level
    const dy = (clientY - dragRef.current.startY) / zoomLevel;

    // Optional: Add boundaries
    const container = containerRef.current;
    if (container) {
      const img = container.querySelector(`.${styles.floorPlanImage}`);
      const rect = container.getBoundingClientRect();
      const imgRect = img.getBoundingClientRect();
      
      const maxX = Math.max(0, (imgRect.width * zoomLevel - rect.width) / 2) / zoomLevel;
      const maxY = Math.max(0, (imgRect.height * zoomLevel - rect.height) / 2) / zoomLevel;

      const newX = Math.max(-maxX, Math.min(maxX, dragRef.current.initialX + dx));
      const newY = Math.max(-maxY, Math.min(maxY, dragRef.current.initialY + dy));

      setPosition({ x: newX, y: newY });
    } else {
      setPosition({
        x: dragRef.current.initialX + dx,
        y: dragRef.current.initialY + dy,
      });
    }
  };

  const endDrag = () => {
    setIsDragging(false);
  };

  // Mouse events
  const handleMouseDown = (e) => {
    startDrag(e.clientX, e.clientY);
  };

  const handleMouseMove = (e) => {
    moveDrag(e.clientX, e.clientY);
  };

  const handleMouseUp = () => {
    endDrag();
  };

  // Touch events
  const handleTouchStart = (e) => {
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      startDrag(touch.clientX, touch.clientY);
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      moveDrag(touch.clientX, touch.clientY);
    }
  };

  const handleTouchEnd = () => {
    endDrag();
  };

  if (loading) {
    return <div className={styles.loading}>Loading blueprint...</div>;
  }

  if (!floorPlans || floorPlans.length === 0) {
    return <div className={styles.noFloorPlans}>No blueprints available.</div>;
  }

  return (
    <div className={`${styles.galleryContainer} ${isFullscreen ? styles.fullscreen : ''}`}>
      <div 
        ref={containerRef}
        className={styles.imageContainer}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onWheel={handleWheel}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div 
          className={styles.imageWrapper}
          style={{
            transform: `translate(${position.x}px, ${position.y}px) scale(${zoomLevel})`,
            cursor: zoomLevel > 1 ? (isDragging ? 'grabbing' : 'grab') : 'default',
          }}
        >
          <img
            src={floorPlans[currentIndex]}
            alt={`Blueprint ${currentIndex + 1}`}
            className={styles.floorPlanImage}
          />
        </div>

        <div className={styles.controls}>
          <button onClick={() => handleNavigation('prev')} className={styles.navButton}>
            <ChevronLeft size={24} />
          </button>
          
          <div className={styles.imageInfo}>
            <span>{`Floor Plan ${currentIndex + 1} of ${floorPlans.length}`}</span>
            <div className={styles.zoomControls}>
              <button onClick={() => handleZoom(-1)} className={styles.zoomButton}>
                <ZoomOut size={20} />
              </button>
              <button onClick={() => handleZoom(1)} className={styles.zoomButton}>
                <ZoomIn size={20} />
              </button>
              {!isFullscreen && (
                <button onClick={toggleFullscreen} className={styles.fullscreenButton}>
                  <Expand size={20} /> Fullscreen
                </button>
              )}
            </div>
          </div>

          <button onClick={() => handleNavigation('next')} className={styles.navButton}>
            <ChevronRight size={24} />
          </button>
        </div>

        {isFullscreen && (
          <button onClick={toggleFullscreen} className={styles.closeButton}>
            <X size={24} />
          </button>
        )}
      </div>

      {!isFullscreen && (
        <div className={styles.thumbnailsContainer}>
          {floorPlans.map((plan, index) => (
            <div
              key={index}
              className={`${styles.thumbnailItem} ${currentIndex === index ? styles.active : ''}`}
              onClick={() => setCurrentIndex(index)}
            >
              <img src={plan} alt={`Thumbnail ${index + 1}`} className={styles.thumbnailImage} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FloorplanGallery;